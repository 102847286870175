import React, { useRef } from 'react';
import { useEffect, useState } from "react";
import Select from 'react-select';
import "../../assets/css/master.css"
import { getAllData, postUsersData, patchUsersData, verifytoken } from '../../services/ApiCallingUserServices';
import Switch from 'react-switch';
import { alphaNumeric, alphaNumericspace, generatePDF, isValidEmail, onlyNumeric } from '../commonClass/CommonValidation';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearLocalStorage, GetLocalStorage } from '../commonClass/Session';
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { ConfirmationModal } from '../../customControl/ConfirmationModal';
import CustomSelection from '../../customControl/CustomSelection';
import DataTable from 'react-data-table-component';
import Role from './Role';
import { DepartmentModel } from './Modals/RightsModel';
import { ExportExcel } from '../commonClass/ExportExcel';

const Department = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState<any>({});
    const [buttonaddeditText, setButtonAddEditText] = useState("Add");
    const [editdisabled, setEditDisabled] = useState(true);
    const [activeId, setActiveId] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [clrshow, setsclshow] = useState(false);
    const [departmentdata, setDepartmentData] = useState<any>([]);
    const [useredit, setUserEdit] = useState(true);
    const [useradd, setUserAdd] = useState(true);
    const [userexport, setUserExport] = useState(true);
    const [citydisable, setCityDisable] = useState(false);
    const user = useSelector((store: any) => store.userstatus.tba);
    const [isSubmit, setIsSubmit] = useState(false);
    const [form, setForm] = useState(new DepartmentModel());
    const onSubmitForm = (e: any) => {
        e.preventDefault();
        setFormErrors(validate(form));
    };
    const handleisActive = (e: any) => {
        if (buttonaddeditText === 'Add') {
            form.IsActive = e
            setActiveId(!activeId)
            setFormErrors('');
        }
        else {
            setModalShow(true);
        }
    }
    const handleConfirmation = (e: any) => {
        if (e === true) {
            form.IsActive = !activeId
            setActiveId(!activeId)
            setEditDisabled(true);
            setFormErrors('');
        }
        setModalShow(false);
    }
    //#region bind datatable
    const columns: any = [
        {
            name: 'Department Name',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (department: any) => department.DepartmentName
        },

        {
            name: 'Active',
            sortable: true,
            float: "left",
            // selector: location => <td style={{ textAlign: "center", width: "5%" }}>
            //     {`${location.inActive ? 'InActive' : 'Active'}`}</td>
            selector: (department: any) => department.IsActive ? 'Active' : 'InActive'
        },
        {
            name: 'Created Date',
            sortable: true,
            reorder: true,
            float: "left",
            selector: (department: any) => formatDateRequire(department.CreatedOn)
        },
        {
            name: 'Edit',
            // float:"right",
            // selector: location => < td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleEditMode(location.tagId)}> <i className="fa fa-edit"></i></td >
            selector: (department: any) => <button type='button' className='border-0 p-0 bg-body set_disableclr' disabled={!useredit}><i className="fa fa-edit" onClick={() => handleEditMode(department.DepartmentId)}></i></button>
        },
        {
            name: 'View',
            // float:"right",
            // selector: location => <td style={{ textAlign: "center", width: "5%", cursor:"pointer" }} onClick={() => handleViewMode(location.tagId)}><i className="fa fa-eye"></i></td>
            selector: (department: any) => <i className="fa fa-eye" onClick={() => handleViewMode(department.DepartmentId)}></i>// onClick={() => handleViewMode(banner.bannerId)}
        }
    ];
    function formatDateRequire(date: any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    //#endregion

    //#region handling search
    const serachoption = [
        {
            label: 'Department Name',
            value: '1'
        },
        {
            label: 'Active',
            value: '5'
        },
        {
            label: 'All',
            value: '6'
        },

    ];

    useEffect(() => {
        dispatch(loaderActions.start());
        let token = GetLocalStorage();
        if (token && token.userName && token.token) {
            setForm({...form, UserId : token?.userMasterID})
            getdepartmentlist();
            setitems();
        }
        else {
            ClearLocalStorage();
            dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
        }

    }, []);

    useEffect(() => {
        let pagelink = user?.find((x: any) => {
            if (x.PageLink === window.location.pathname)
                return x.MenuId
        });
        let mid = user?.find((x: any) => x.MenuId === pagelink.MenuId);
        if (mid?.URightView === false && mid?.URightView !== undefined) {
            navigate('/*');
        }
        user?.filter((x: any) => x.MenuId === pagelink.MenuId).map((obj: any) => {
            setUserAdd(obj.URightAdd);
            setUserEdit(obj.URightEdit);
            setUserExport(obj.URightExport);

        });
    }, [user])
    //#endregion

    const getdepartmentlist = async () => {
        await getAllData('/auth/v1/getalluserdepartment?UserType='+form.UserType).then((departmentdata) => {
            setDepartmentData(departmentdata.data);
            dispatch(loaderActions.end());
        });
    }
    const handleEditMode = (id: any) => {
        setDisabled(false);
        setButtonAddEditText("Update");
        setSerachDisable(true);
        RetriveData(id);
        setEditDisabled(false);
    }
    const handleViewMode = (id: any) => {
        setDisabled(true);
        setEditDisabled(false);
        setSerachDisable(true);
        RetriveData(id);
    }
    function RetriveData(id: any) {
        setFormErrors('');
        departmentdata?.map((obj: any) => {
            if (obj.DepartmentId === id) {
                setForm({ ...form, DepartmentId: obj.DepartmentId, DepartmentName: obj.DepartmentName, IsActive: obj.IsActive })
                setActiveId(obj.IsActive);
            }
        });

    }
    const validate = (values: any) => {
        setIsSubmit(true);
        const error: any = {}
        if (!values.DepartmentName || values.DepartmentName?.trim()?.length === 0) {
            error.DepartmentName = "Kindly enter the department name";
            setIsSubmit(false);
        }
        return error;
    }

    const handleSubmitData = async () => {
        let statusoftoken = await verifytoken();
        if (buttonaddeditText === 'Add') {
            if (statusoftoken) {
                const DepartmentData = await postUsersData('/auth/v1/insertuserdepartment', form)
                const status = DepartmentData.status;
                if (!DepartmentData.status && !DepartmentData.data) {
                    dispatch({ type: 'ALERTING', message: DepartmentData.message });
                    setDisabled(false);
                    setEditDisabled(true);
                    setIsSubmit(false);
                    return;
                }
                if (status) {
                    dispatch({ type: 'ALERTING', message: DepartmentData.message });
                    setitems();
                    clear();
                    setDisabled(false);
                    setEditDisabled(true);
                }
                else {
                    dispatch({ type: 'ALERTING', message: DepartmentData.message })
                    setDisabled(false);
                    setEditDisabled(true);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }
        else {
            if (statusoftoken) {
                const DepartmentData = await patchUsersData('/auth/v1/updateuserdepartment', form)
                const status = DepartmentData.status;
                if (!DepartmentData.status && !DepartmentData.data) {
                    dispatch({ type: 'ALERTING', message: DepartmentData.message });
                    setDisabled(false);
                    setEditDisabled(true);
                    setIsSubmit(false);
                    return;
                }
                if (status) {
                    dispatch({ type: 'ALERTING', message: DepartmentData.message });
                    setitems();
                    clear();
                }
                else {
                    dispatch({ type: 'ALERTING', message: DepartmentData.message })
                    setDisabled(false);
                    setEditDisabled(true);
                    return;
                }
            }
            else {
                ClearLocalStorage();
                dispatch({ type: 'SESSIONOUT', message: 'Your Session Expired. Please Login Again!' })
            }
        }

    }
    useEffect(() => {
        if (Object.keys(formErrors)?.length === 0 && isSubmit) {
            setDisabled(true);
            setEditDisabled(false);
            handleSubmitData();
        }
        if (Object.keys(formErrors).length > 0) {

            let id = Object.keys(formErrors)[0];
            if (id === "LocationId") {
                eval(id).current.focus();
            }
            else {
                let doc = document.getElementById(id);
                if (doc !== null)
                    doc.focus();
            }
        }

    }, [formErrors]);

    const onChange = (e: any) => {
        var result;
        if ([e.target.name][0] === 'DepartmentName') {
            result = alphaNumericspace(e.target.value)
        }
        // else if ([e.target.name][0] === 'GSTNNo') {
        //     result = alphaNumeric(e.target.value)
        // }
        else {
            result = e.target.value;
        }
        setForm({ ...form, [e.target.name]: result });
        setEditDisabled(true);
        setFormErrors('');
    }
    const handleClearClick = () => {
        clear();
    }
    function clear() {
        setButtonAddEditText('Add');
        setIsSubmit(false);
        setDisabled(false);
        setFormErrors('');
        setForm({
            ...form, DepartmentName: '',IsActive: false, DepartmentId: 0,
        });
        setActiveId(false);
        getdepartmentlist();
        setCityDisable(false);
        setEditDisabled(true);
        setSerachDisable(false);
        setSerachBy('6');
        setSearchValue('');
    }
    const [searchvalue, setSearchValue] = useState('');
    const [searchby, setSerachBy] = useState<any>('6');
    const [serchdisable, setSerachDisable] = useState(false);
    const [filteritems, setFilterItems] = useState<any>([]);

    const setitems = async () => {
        await getAllData('/auth/v1/getalluserdepartment?UserType='+form.UserType).then((departmentdata) => {
            setFilterItems(departmentdata.data);
        });
    }

    const handleSearchBy = (e: any) => {
        setSerachBy(e.value);
    }
    const handleChangeSearchValue = (e: any) => {
        setSearchValue(e.target.value)
        setsclshow(true)
        if (e.target.value === '') {
            getdepartmentlist();
            setsclshow(false);
        }
    }

    const onKeyDownHandler = (e: any) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    };
    function formatDate(date: any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    const handleSearch = () => {
        if (searchvalue?.trim()?.length !== 0 && searchby > 0) {
            setitems();
            var filteritem: any = filteritems;
            var filterdata;
            if (searchby === '1') {
                filterdata = filteritem?.filter((issec: any) => issec.DepartmentName.toLowerCase().includes(searchvalue.toLowerCase()));
            }
            else if (searchby === '5') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter((issec: any) => issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter((issec: any) => issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec: any) => issec.IsActive === true || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec: any) => issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter((issec: any) => formatDate(issec.CreatedOn).toLowerCase().includes("%#$&%^"));
                }
            }
            else if (searchby === '6') {
                if (searchvalue.toLowerCase() === 'active'.toLowerCase()) {
                    filterdata = filteritem?.filter((issec: any) => issec.DepartmentName.toLowerCase().includes(searchvalue.toLowerCase())
                    ||formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true);
                }
                else if (searchvalue.toLowerCase() === 'inactive'.toLowerCase()) {
                    filterdata = filteritem?.filter((issec: any) => issec.DepartmentName.toLowerCase().includes(searchvalue.toLowerCase())
                    ||formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else if ('active'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec: any) => issec.DepartmentName.toLowerCase().includes(searchvalue.toLowerCase())
                    ||formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === true
                        || issec.IsActive === false);
                }
                else if ('inactive'.toLowerCase().includes(searchvalue.toLowerCase())) {
                    filterdata = filteritem?.filter((issec: any) => issec.DepartmentName.toLowerCase().includes(searchvalue.toLowerCase())
                    ||formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || issec.IsActive === false);
                }
                else {
                    filterdata = filteritem?.filter((issec: any) => issec.DepartmentName.toLowerCase().includes(searchvalue.toLowerCase())
                    ||formatDate(issec.CreatedOn).toLowerCase().includes(searchvalue.toLowerCase())
                        || 'active'.toLowerCase().includes(searchvalue.toLowerCase()) || 'inactive'.toLowerCase().includes(searchvalue.toLowerCase()));
                }
            }
            setDepartmentData(filterdata)
        }
    }

    const clearsearch = () => {
        setSearchValue("");
        setsclshow(false);
        getdepartmentlist();
    }
    //#endregion
    //#region Export
    const PdfExport = () => {
        const columns = [
            'Department Name', 'Active', 'Created Date'
        ];
        var rows: any = [];

        for (let i = 0; i < departmentdata.length; i++) {
            var temp = [
                departmentdata[i].DepartmentName,
                `${departmentdata[i].IsActive ? 'Active' : 'InActive'}`,
                formatDate(departmentdata[i].CreatedOn)
            ];
            rows = [...rows, temp]
        }
        generatePDF(columns, rows, "DepartmentList", "Department List")
    }
    const ExportToExcel = () => {
        const dispalyname = ['Department Name', 'Active', 'Created Date'];
        const fields = ['DepartmentName', 'IsActive', 'CreatedOn'];
        ExportExcel(dispalyname, fields, departmentdata, "DepartmentList", "DepartmentList.xls");
    }
    //#endregion Export


    return (
        <>

            <div className="container-fluid mt-4 heading_color">
                <h3>Department & Role</h3>
            </div>
            <div className="container-fluid form_border my-3 pb-5">
                <div className='container mt-3 mb-5 tab_section px-0'>
                    <ul className="nav nav-pills nav-justified mb-3 pt-3 mx-5 px-5" id="pills-tab" role="tablist">
                        <li className="nav-item mx-lg-5 mx-md-1 mx-1 mt-1" role="presentation">
                            <a className="nav-link active" id="pills-Department-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Department" type="button" role="tab" aria-controls="pills-about"
                                aria-selected="true"><strong>Department</strong></a>
                        </li>
                        <li className="nav-item mx-lg-5 mx-md-1 mx-1 mt-1" role="presentation" >
                            <a className="nav-link" id="pills-Role-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-Role" type="button" role="tab" aria-controls="pills-tips"
                                aria-selected="false"><strong >Role</strong></a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-Department" role="tabpanel"
                        aria-labelledby="pills-Department-tab">
                        <form action="" >
                            <div className="mb-1 row mx-1">
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12">
                                            <label htmlFor="inputPassword6" className="col-form-label required">Department Name</label>
                                        </div>
                                        <div className=" col-lg-8 col-md-12 col-sm-12">
                                            <input className="form-control"
                                                type="text"
                                                id='DepartmentName'
                                                name="DepartmentName"
                                                value={form.DepartmentName}
                                                onChange={onChange}
                                                disabled={disabled ? true : false}
                                                placeholder='Department Name'
                                                maxLength={35}
                                            />
                                            {formErrors.DepartmentName && <p className='text-danger text-start'>{formErrors.DepartmentName}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12">
                                            <label htmlFor="inputPassword6" className="col-form-label">Active</label>
                                        </div>
                                        <div className="col-auto col-lg-6">
                                            <Switch
                                                onChange={handleisActive}
                                                checked={activeId}
                                                className="react-switch"
                                                disabled={disabled ? true : false}
                                            />
                                        </div>
                                        <ConfirmationModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            noconfirm={() => handleConfirmation(false)}
                                            yesconfirm={() => handleConfirmation(true)}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 d-flex justify-content-center">
                                {useradd === false && buttonaddeditText === "Add" ?
                                    <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={!useradd}>{buttonaddeditText}</button> :
                                    <button type="button" className="btn btn-primary mx-1" id="btnAdd" onClick={onSubmitForm} disabled={editdisabled ? false : true}>{buttonaddeditText}</button>}
                                <button type="button" className="btn btn-primary mx-1" id="btnClear" onClick={handleClearClick}>Clear</button>
                            </div>
                            <div className="row mt-3">
                                <div className='d-lg-flex d-md-flex d-sm-block justify-content-end'>
                                    <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                        <Select
                                            className="dropdown"
                                            options={serachoption}
                                            value={serachoption?.filter(function (serachoption) {
                                                return serachoption.value === searchby;
                                            })}// set list of the dataalue 
                                            onChange={handleSearchBy} // assign onChange function
                                            isDisabled={serchdisable ? true : false}
                                            isSearchable={false}

                                        />
                                    </div>
                                    <div className="p-2 col-lg-2 col-md-4 col-sm-12">
                                        <div className='clrbutton'>
                                            <input type="text" onKeyDown={onKeyDownHandler} id="btnSearchText" className="form-control" placeholder='Enter keyword' name="SearchValue" onChange={handleChangeSearchValue} value={searchvalue || ''} disabled={serchdisable ? true : false} />
                                            <button className={`${clrshow === true ? "d-block" : "d-none"}`} disabled={serchdisable ? true : false} onClick={clearsearch} type="reset">&times;</button>
                                        </div>
                                    </div>
                                    <div className="p-2 text-center">
                                        <button type="button" className="btn btn-primary mx-1" id="btnSearch" disabled={serchdisable ? true : false} onClick={handleSearch} >Search</button>
                                    </div>
                                </div>

                            </div>
                            <div className="mb-3 row mx-1">
                                <div className="col-lg-12">
                                    <div className="mb-3 row form_border py-3">
                                        <div className="col-lg-12 mb-1 table-scrollable">
                                            {departmentdata &&
                                                <DataTable
                                                    // title="Location"
                                                    columns={columns}
                                                    data={departmentdata}
                                                    highlightOnHover
                                                    pagination
                                                    // defaultSortFieldId={1}
                                                    paginationPerPage={5}
                                                    paginationRowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
                                                    // defaultSortFieldID={1}
                                                    // striped
                                                    responsive

                                                />
                                            }
                                        </div>
                                        <div>
                                            <button type='button' disabled={!userexport} className='border-0 p-0 bg-body me-1 set_disableclr'><i className="fa fa-file-pdf-o" aria-hidden="true" style={{ fontSize: "28px", color: "red" }} onClick={PdfExport}></i></button>
                                            <button type='button' disabled={!userexport} className='border-0 p-0 bg-body me-1 set_disableclr'><i className="fa fa-file-excel-o" aria-hidden="true" style={{ fontSize: "28px", color: "green" }} onClick={ExportToExcel}></i></button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="tab-pane fade show" id="pills-Role" role="tabpanel"
                        aria-labelledby="pills-Role-tab">
                        <Role />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Department