import { useEffect, useState } from "react";
import LandingPageDesign from "./LandingPageDesign";
import OtpModel from "./OtpModel";
import {
  forgotPatchReq,
  getOtpPostReq,
} from "../../services/ApiCallingUserServices";
import { isNotaNumber } from "../commonClass/CommonValidation";
import { useDispatch } from "react-redux";
import { loaderActions } from "../../_actions/loader.actions";
import { useNavigate } from "react-router";

function Forgot() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotValue, setForgotValue] = useState<any>("");
  const [show, setShow] = useState<any>(false);
  const [errors, setErrors] = useState<string>("");
  const [forgetData, setforgetData] = useState({});
  const createValidUserIdDetails = (passwordId: any) => {
    let data: object = {};
    let userId = passwordId.trim();
    if (isNotaNumber(userId)) {
      data = {
        userEmail: userId,
        contactNo: "",
        userType: 3,
        otpType: 1,
        userName: userId,
      };
    } else {
      data = {
        userEmail: "",
        contactNo: userId,
        userType: 3,
        otpType: 2,
        userName: userId,
      };
    }

    return data;
  };

  const sendOtpOfFOrgotPassword = async (e: any) => {
    e.preventDefault();
    dispatch(loaderActions.start());

    if (!forgotValue.trim()) {
      setErrors("Kindly enter your email/mobile no.");
      dispatch(loaderActions.end());
      return;
    }

    let data = createValidUserIdDetails(forgotValue);
    const forgotpassword = await forgotPatchReq(
      "/auth/v1/forgetpassword",
      data
    );

    if (forgotpassword?.status) {
      setforgetData(forgotpassword?.data);

      const sendotp = await getOtpPostReq("/auth/v1/getotp", data);

      if (sendotp.status) {
        dispatch(loaderActions.end());
        setShow(true);
      } else {
        dispatch(loaderActions.end());
        setErrors(sendotp.message);
      }
    } else {
      dispatch(loaderActions.end());
      setErrors("User doesn't exist.");
    }
  };

  useEffect(() => {
    dispatch(loaderActions.end());
  }, [show]);

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(()=>{
    window.history.forward();
 },[])

  return (
    <>
      <LandingPageDesign>
        <OtpModel
          show={show}
          setShow={setShow}
          userDetails={forgotValue.trim()}
          sendOtpOfFOrgotPassword={sendOtpOfFOrgotPassword}
          forgetData={forgetData}
        ></OtpModel>

        {/* <div className="card  border-0 rounded-lg z-0"> */}
        <div className="card-header forgot_header bg-body border-0 z-0">
          <h2 className="   text-start font-weight-light my-2 color_newblue fw-bold">
            Forgot Password
          </h2>
        </div>

        {/* <div className="card-header bg-body border-0 z-0">
                    <div className='row'>
                        <div className='col-2 text-center align-self-center'>
                            <i
                                className=" fa fa-2x fa-arrow-left cursor_pointer"
                                onClick={handleBack}
                            ></i>
                        </div>
                        <div className='col-10'>
                            <h2 className="text-start font-weight-light color_newblue my-2 z-0">Forgot Password</h2>
                        </div>
                    </div>
                </div> */}

        <div className="card-body">
          <form onSubmit={sendOtpOfFOrgotPassword}>
            <div className="row">
              <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                <label
                  htmlFor="userid"
                  className="col-form-label text-muted required"
                >
                  Email or Mobile No.
                </label>
              </div>

              <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Email or Mobile No."
                  name="userName"
                  value={forgotValue}
                  onChange={(e) => {
                    setErrors("");
                    setForgotValue(e.target.value);
                  }}
                  maxLength={150}
                  autoComplete={'off'}
                // disabled={disabled ? true : false}
                />
                {errors && <p className="text-danger text-start">{errors}</p>}
              </div>
            </div>

            <div className="row">
              <div className="d-flex align-items-center justify-content-center mt-4 mb-0">
                <button
                  type="submit"
                  className="btn btn-primary col-xxl-12 col-lg-12 col-md-12 col-sm-12"
                  disabled={show}
                >
                  Send OTP
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* <div className="card-footer text-center py-3">
                                    <div className="small"><a href="register.html">Need an account? Sign up!</a></div>
                                </div> */}

        {/* </div> */}
      </LandingPageDesign>
    </>
  );
}

export default Forgot;
